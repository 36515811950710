import { toast } from 'react-toastify'
import queryString from 'query-string'
export const isBrowser = () => typeof window !== 'undefined'

export const isIos = () => isBrowser() && /(iPhone|iPad|iPod|iOS)/i.test(window.navigator.userAgent)

export const isAndroid = () => isBrowser() && /(Android)/i.test(window.navigator.userAgent)

export const isWindowsPhone = () => isBrowser() && /(Windows Phone)/i.test(navigator.userAgent)

export const isPc = () => isBrowser() && window.document.body.clientWidth > 1080

export const ua = (isBrowser() && window.navigator.userAgent) || ''

// 微信环境
export const isWeChat = /micromessenger/i.test(ua.toLocaleLowerCase())
// 企业微信
export const isWxWork = isWeChat && /wxwork/i.test(ua.toLocaleLowerCase())

export const SFContentFormat = (SFCampaign) => {
  var contentArr = JSON.parse(SFCampaign.content)
  var params = {}
  contentArr.forEach(function (item) {
    params[item.name] = item.value
  })
  return params
}

export const isEmail = (val) => /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(val.trim())

// 节流
export const throttle = (func, wait) => {
  let canRun = true
  return function (...args) {
    if (!canRun) return
    canRun = false
    const context = this
    setTimeout(() => {
      func.apply(context, args)
      canRun = true
    }, wait)
  }
}

// 手机号脱敏
export const sencePhone = (val) => {
  if (val) {
    return val.toString().replace(/(\d{3})\d*(\d{4})/, '$1****$2')
  }
}

var curScroll = 0 // 当前滚动位置
// 获取当前滚动位置
const getCurScroll = () => {
  const scrollTop = Math.max(document.body.scrollTop, document.documentElement.scrollTop)
  return scrollTop
}

// 禁止滚动
export const forbidScroll = () => {
  curScroll = getCurScroll()
  window.document.body.style.position = 'fixed'
  window.document.body.style.top = '-' + curScroll + 'px'
  window.document.body.style.width = '100%'
}

// 释放滚动
export const allowScroll = () => {
  window.document.body.style.position = ''
  window.document.body.style.top = ''
  window.document.body.style.width = ''
  window.scrollTo(0, curScroll)
}

export const toastError = (message) => {
  toast.error(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  })
}

export const toastSuccess = (message) => {
  toast.success(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  })
}

//获取地址栏参数
export const getSearchParams = (searchName) => {
  const search = window?.location.search
  if (!search) return ''

  const arr = search?.split('?')
  const obj = '{"' + arr[1].replace(/=/g, '":"').replace('&', '","') + '"}'
  const json = JSON.parse(obj)
  return json[searchName] ? json[searchName] : ''
}

export default function loadScript(src, id) {
  if (!document.getElementById(id)) {
    const script = document.createElement('script')
    script.src = src
    script.id = id
    script.async = true
    document.body.appendChild(script)
  }
}

export function queryToSearch(query) {
  for (const i in query) {
    if (Object.prototype.hasOwnProperty.call(query, i)) {
      const val = query[i]

      if (typeof val === 'object') {
        // eslint-disable-next-line no-param-reassign
        query[i] = JSON.stringify(query[i])
      }

      if (query[i] === undefined || query[i] === null || query[i] === '') {
        // eslint-disable-next-line no-param-reassign
        delete query[i]
      }
    }
  }

  return query ? `?${queryString.stringify(query)}` : ''
}

//去除对象中的空格，用于简单对象
export const removeEmptyValue = (params) => {
  const obj = {}

  for (const item in params) {
    if (Object.prototype.hasOwnProperty.call(params, item)) {
      if (Object.prototype.toString.call(params[item]) === '[object Object]') {
        return removeEmptyValue(params[item])
      }

      if (params[item] !== undefined && params[item] !== '' && params[item] !== null) {
        obj[item] = params[item]
      }
    }
  }

  return obj
}
